<template>
    <div class="darmin card">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-6">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Procedimiento</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="p in estadisticas.procedimientos" :key="p.name">
                                    <td>{{ p.name }}</td>
                                    <td>{{ p.count }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Canceladas</td>
                                    <td>{{ estadisticas.estados[0] }}</td>
                                </tr>
                                <tr>
                                    <td>Programadas</td>
                                    <td>{{ estadisticas.estados[1] }}</td>
                                </tr>
                                <tr>
                                    <td>En Sala de Espera</td>
                                    <td>{{ estadisticas.estados[2] }}</td>
                                </tr>
                                <tr>
                                    <td>Finalizadas</td>
                                    <td>{{ estadisticas.estados[3] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({ events: Array });

const estadisticas = computed(() => {
    const s = {
        procedimientos: [],
        estados: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
        },
    };

    props.events.forEach((e) => {
        console.log(e);
        if (e.setmode !== 2) {
            const i = s.procedimientos.findIndex((p) => p.name === 'Consulta');
            console.log('i', i);
            if (i >= 0) {
                s.procedimientos[i].count += 1;
            } else {
                s.procedimientos.push({ name: 'Consulta', count: 1 });
            }
        } else {
            const i = s.procedimientos.findIndex((p) => p.name === e.motivo.name);
            console.log('i', i);
            if (i >= 0) {
                s.procedimientos[i].count += 1;
            } else {
                s.procedimientos.push({ name: e.motivo.name, count: 1 });
            }
        }
        s.estados[e.status] += 1;
    });

    return s;
});
</script>

<style lang="scss" scoped></style>
