<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-lg-12">
                <div class="darmin card header-card theme-form d-print-none">
                    <div class="row">
                        <div class="d-flex flex-column align-items-end justify-content-end gap-3">
                            <div class="m-0 p-0 g-0 gap-1 d-flex align-items-center">
                                <label class="form-label text-nowrap m-0">Especialista</label>
                                <div class="d-flex">
                                    <Autocomplete :debounce="1200" @onInput="getEspecialistas" :items="especialistas"
                                        :displayItem="(item) =>
                                            typeof item === 'string' ? item : item.fullName
                                            " placeholder="Buscar un Especialista" @onSelect="onSelectEspecialista"
                                        @onReset="onResetEspecilista" ref="especialistaAutocomplete"
                                        :results-container-class="[
                                            'darmin__vue3-results-container',
                                        ]"></Autocomplete>
                                </div>
                            </div>
                            <div class="m-0 p-0 g-0 gap-1 d-flex align-items-center">
                                <label class="form-label text-nowrap m-0">Fecha</label>
                                <div class="d-flex">
                                    <DateInput v-model="fecha" @onChage="handleGetCitas()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="darmin card">
                    <div class="card-body row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Paciente</th>
                                            <th>Documento</th>
                                            <th>Contacto</th>
                                            <th>Entidad</th>
                                            <th>Procedimiento</th>
                                            <th>Estado</th>
                                            <th class="d-none d-print-table-cell">Observaciones</th>
                                            <th class="d-print-none">...</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="cita in citasOrdered" :key="cita.id">
                                            <tr>
                                                <td>
                                                    {{ normarlizeDate(cita.timeStart) }}
                                                </td>
                                                <td>{{ cita.paciente.fullName }}</td>
                                                <td>
                                                    {{ cita.paciente.identification_type }}
                                                    {{ cita.paciente.identification }}
                                                </td>
                                                <td>{{ cita.paciente.contact[0] || '' }}</td>
                                                <td>{{ cita.paciente.eps?.name || '-' }}</td>
                                                <td>{{ procedimiento(cita) }}</td>
                                                <td>{{ status(cita.status) }}</td>
                                                <td class="d-none d-print-table-cell">
                                                    <p
                                                        v-for="(o, i) in cita.observaciones"
                                                        :key="i"
                                                    >
                                                        {{ o.contenido }}
                                                    </p>
                                                </td>
                                                <td class="d-print-none">
                                                    <div class="d-flex">
                                                        <i
                                                            class="far fa-file-alt"
                                                            role="button"
                                                            @click="handleOpenCita(cita)"
                                                        ></i>
                                                        <i
                                                            class="ms-3 far fa-user"
                                                            role="button"
                                                            @click="handleOpenPaciente(cita.paciente)"
                                                        ></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer py-3 d-print-none">
                        <div class="d-flex flex-column align-items-end flex-md-row justify-content-end"
                            style="font-size: 0.7rem">
                            <div><i class="far fa-lg fa-file-alt"></i> Ver Cita</div>
                            <div class="ms-2"><i class="far fa-lg fa-user"></i> Ver Paciente</div>
                        </div>
                    </div>
                </div>
                <CitasSummaryCard class="d-print-none" :events="events" v-if="citasOrdered.length" />
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';

import WindowLayout from '@/layouts/Window.layout.vue';
import DateInput from '@/components/form/DateInput.vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import CitasSummaryCard from './components/CitasSummaryCard.vue';

import useDoctor from '../Doctores/services/useDoctor';
import { useCitas } from '../Agenda/services/useCitas';

export default {
    name: 'DailyReport',
    components: {
        WindowLayout,
        DateInput,
        Autocomplete,
        CitasSummaryCard,
    },
    props: {
        title: { type: String, default: 'Lista' },
    },
    setup() {
        const store = useStore();
        const { doctores: especialistas, getDoctores: getEspecialistas } = useDoctor();
        const { citas: events, getCitas, buildFilters: citasFilter } = useCitas();
        const fecha = ref(DateTime.now().startOf('day'));
        const especialista = ref();

        const handleGetCitas = () => {
            if (fecha.value) {
                const fechaObj = DateTime.fromISO(fecha.value);

                const filters = citasFilter(
                    fechaObj,
                    // eslint-disable-next-line comma-dangle
                    fechaObj.plus({ day: 1 }),
                    // eslint-disable-next-line no-undef
                    undefined,
                    // eslint-disable-next-line comma-dangle
                    especialista.value
                );
                getCitas(filters);
            }
        };

        const especialistaAutocomplete = ref(); // autocomplete input
        const especialistaSelected = ref();
        const onSelectEspecialista = (doctor) => {
            console.log(doctor.id);
            especialista.value = doctor.id;
            especialistaAutocomplete.value.setText(doctor.fullName);
            especialistaSelected.value = doctor;
            handleGetCitas();
        };

        const onResetEspecilista = () => {
            especialista.value = undefined;
            especialistaAutocomplete.value.setText('');
            especialistaSelected.value = undefined;
            handleGetCitas();
        };

        watch(
            () => fecha.value,
            () => {
                handleGetCitas();
                // eslint-disable-next-line comma-dangle
            }
        );

        const citasOrdered = computed(() => {
            const ar = [...events.value];

            ar.sort((a, b) => {
                const aS = DateTime.fromISO(a.timeStart);
                const bS = DateTime.fromISO(b.timeStart);
                return aS.toMillis() - bS.toMillis();
            });

            return ar;
        });

        const procedimiento = (event) => {
            if (event.setmode !== 2) {
                if (event.type === 'procedimiento') {
                    return event.procedimiento?.name || event.motivo?.name || 'procedimiento';
                }
                return 'Consulta';
            }
            return event.motivo.name;
        };

        const status = (s) => {
            switch (s) {
                case 1:
                case '1':
                    return 'Programada';
                case 2:
                case '2':
                    return 'En sala de espera';
                case 3:
                case '3':
                    return 'Finalizada';
                case 0:
                case '0':
                    return 'Cancelada';
                default:
                    return '';
            }
        };

        const handleOpenCita = (cita) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'CitaForm',
                id: `CitaForm_${cita.id}`,
                name: 'Cita',
                params: {
                    name: 'Paciente',
                    headerTitle: 'Ver Cita',
                    item: cita,
                },
            });
        };
        const handleOpenPaciente = (paciente) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'PacienteForm',
                id: `PacienteForm_${paciente.id}`,
                name: 'Paciente',
                params: {
                    name: 'Paciente',
                    headerTitle: 'Editar Paciente',
                    item: paciente,
                },
            });
        };

        const normarlizeDate = (date) => DateTime.fromISO(date).toFormat('dd LLL yyyy hh:mm a', { locale: 'co' });
        onBeforeMount(() => { });

        onMounted(() => {
            handleGetCitas();
        });

        return {
            fecha,
            handleGetCitas,
            especialista,
            especialistas,
            getEspecialistas,
            especialistaAutocomplete,
            especialistaSelected,
            onSelectEspecialista,
            onResetEspecilista,
            citasOrdered,
            procedimiento,
            normarlizeDate,
            status,
            handleOpenCita,
            handleOpenPaciente,
            events,
        };
    },
};
</script>

<style scoped></style>
